.main {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  img {
    margin: auto;
    max-width: 320px;
  }

  .buttons {
    display: flex;

  }
}